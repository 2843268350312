import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
@Component({
  selector: 'rf-err',
  template: `
    <section>
      <div
        *ngIf="
          rformControl.invalid && (rformControl.dirty || rformControl.touched)
        "
        class="alert alert-danger"
      >
        <div *ngIf="rformControl.errors.required">
          {{ inputTypeName }} is required.
        </div>
        <div *ngIf="rformControl.errors.maxlength">
          {{ inputTypeName }} cannot be more than 50 characters long.
        </div>
        <div *ngIf="rformControl.errors.minlength">
          {{ inputTypeName }} must be at least 2 characters long.
        </div>
      </div>
    </section>
  `,
})
export class ReactiveFormErrorComponent implements OnInit {
  @Input() rformControl;
  @Input() inputTypeName;
  constructor() {}
  ngOnInit() {}
}
