import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'keyFilter',
})
export class KeyFilterPipe implements PipeTransform {
  transform(items: any, filter: any, isAnd: boolean): any {
    if (filter && Array.isArray(items)) {
      let filterKeys = Object.keys(filter);
      if (isAnd) {
        let res = items.filter((item) =>
          filterKeys.reduce(
            (memo, keyName) =>
              (memo && new RegExp(filter[keyName], 'gi').test(item[keyName])) ||
              filter[keyName] === '',
            true
          )
        );
        if (res.length === 0) {
          return [-1];
        } else {
          return res;
        }
      } else {
        let res = items.filter((item) => {
          return filterKeys.some((keyName) => {
            return (
              new RegExp(filter[keyName], 'gi').test(item[keyName]) ||
              filter[keyName] === ''
            );
          });
        });
        if (res.length === 0) {
          return [-1];
        } else {
          return res;
        }
      }
    } else {
      if (items.length === 0) {
        return [-1];
      } else {
        return items;
      }
    }
  }
}
