import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
@Component({
  selector: 'mass-notification-details-card',
  template: `
    <mat-card>
      <mat-card-header>
        <div mat-card-avatar class="example-header-image">
          <mat-icon class="icon">{{ type }}</mat-icon>
        </div>
        <mat-card-title>{{ title }}</mat-card-title>
        <mat-card-subtitle>{{ type | titlecase }} Content</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <mat-form-field
          class="input-form-full"
          appearance="fill"
          color="primary"
        >
          <mat-label>Notification ID</mat-label>
          <input matInput readonly value="{{ notification['_nfid'] }}" />
        </mat-form-field>
        <mat-form-field
          class="input-form-full"
          class="input-form-full"
          appearance="fill"
          color="primary"
          *ngIf="isEmail"
        >
          <mat-label>Subject</mat-label>
          <input matInput readonly value="{{ notification['_nfs'] }}" />
        </mat-form-field>
        <mat-form-field
          class="input-form-full"
          class="input-form-full"
          appearance="fill"
          color="primary"
        >
          <mat-label>Body</mat-label>
          <input matInput readonly />
          <div [innerHTML]="notification['_nfc']"></div>
        </mat-form-field>
        <mat-form-field
          class="input-form-small"
          appearance="fill"
          color="primary"
        >
          <mat-label>Date</mat-label>
          <input
            matInput
            type="text"
            readonly
            value="{{
              notification['_cat'] | date : 'dd/MM/yyyy hh:mm:ss a' : '+0000'
            }}"
          />
        </mat-form-field>
        <mat-form-field
          class="input-form-small"
          appearance="fill"
          color="primary"
        >
          <mat-label>Status</mat-label>
          <input
            matInput
            type="text"
            readonly
            value="{{ notification['_nfst'] }}"
          />
        </mat-form-field>
        <mat-divider></mat-divider>
        <mat-list>
          <div mat-subheader>Users</div>
          <mat-list-item *ngFor="let list of userList">
            <mat-icon mat-list-icon>person</mat-icon>
            <div mat-line>{{ list['user']['email'] }}</div>
            <div mat-line>
              ({{ list['user']['country_code'] }}) {{ list['user']['phone'] }}
            </div>
          </mat-list-item>
        </mat-list>
      </mat-card-content>
    </mat-card>
  `,
})
export class MassNotificationDetailsCardComponent implements OnInit {
  @Input() notification;
  @Input() userList;
  @Input() type;
  @Input() title;
  @Input() icon;
  @Input() isEmail;
  @Output() pageEvent = new EventEmitter();
  constructor() {}
  ngOnInit() {}
}
