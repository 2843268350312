import { Component, OnInit } from '@angular/core';
import { AppState } from '@store';
import { select, Store } from '@ngrx/store';
import selectors from '@store/_selectors';
@Component({
  selector: 'mat-table-loader',
  template: `<div class="ex-container mat-elevation-z8">
    <div class="example-loading-shade" *ngIf="isLoading">
      <mat-spinner
        *ngIf="isLoading"
        mode="indeterminate"
        diameter="50"
      ></mat-spinner>
    </div>
  </div>`,
})
export class LoderComponent implements OnInit {
  public isLoading = true;
  constructor(private _store$: Store<AppState>) {
    this._store$
      .pipe(select(selectors.loderSelector))
      .subscribe((isLoading: boolean) => {
        this.isLoading = isLoading;
      });
  }
  ngOnInit() {}
}
