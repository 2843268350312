<header class="main-header">
  <!-- Logo -->
  <a href="javascript:void(0);" class="logo">
    <!-- mini logo for sidebar mini 50x50 pixels -->
    <span class="logo-mini"><b>B</b></span>
    <!-- logo for regular state and mobile devices -->
    <span class="logo-lg dashboard-logo"><img src="assets/img/dashboard_logo.png"></span>
  </a>
  <!-- Header Navbar -->
  <nav class="navbar navbar-static-top" role="navigation">
    <!-- Sidebar toggle button-->
    <a href="javascript:void(0);" class="sidebar-toggle" data-toggle="push-menu" role="button">
      <span class="sr-only">Toggle navigation</span>
    </a>
    <!-- Navbar Right Menu -->
    <div class="navbar-custom-menu">
      <ul class="nav navbar-nav">
        <!-- Messages: style can be found in dropdown.less-->
        <li class="dropdown messages-menu">
          <!-- Menu toggle button -->
          <a href="javascript:void(0);" (click)="gotoNotifiaction()" class="dropdown-toggle" data-toggle="dropdown" *ngIf="notification > 0 && accessMenuSubAdmin">
            <i class="fa fa-bell-o" aria-hidden="true"></i>
            <span class="label label-success" *ngIf="notification > 0 && accessMenuSubAdmin">{{notification}}</span>
          </a>
          <a href="javascript:void(0);" (click)="gotoNotifiaction()"  class="dropdown-toggle" data-toggle="dropdown" *ngIf="notification <= 0 && accessMenuSubAdmin">
            <i class="fa fa-bell-slash-o" aria-hidden="true"></i>
          </a>
        </li>
        <li class="dropdown user user-menu">
          <a href="javascript:void(0);" class="dropdown-toggle" data-toggle="dropdown">

            <img src="assets/img/profile_logo.png" class="user-image" alt="User Image"> 
            <span class="hidden-xs">{{curUserName}} </span>
          </a>
          <ul class="dropdown-menu">
            <li class="user-footer">
              <div class="pull-left">
                <a (click)="gotoProfile()" href="javascript:void(0);"class="btn btn-default btn-flat">Profile</a>
              </div> 
              <div class="pull-right">
                <a  (click)="logout()" href="javascript:void(0);" class="btn btn-default btn-flat">Sign out</a>
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </nav>
</header>