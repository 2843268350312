import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpInterface } from '@module/shared/_interfaces/http.interface';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { environment } from '@environments/environment';
@Injectable({
  providedIn: 'root',
})
export class SharedService {
  public APIEndpoint = environment.API_ENDPOINT;
  constructor(private http: HttpClient) {}
  public setUserLogout = () => {
    return this.http.get(this.APIEndpoint + 'admin/logout').pipe(
      map((response: HttpInterface) => {
        let responseAny: any = response;
        return responseAny;
      }),
      catchError((err: any) => {
        throw err;
      })
    );
  };
  public getnotificationCounter = () => {
    return this.http.get(this.APIEndpoint + 'admin/notification_counter').pipe(
      map((response: HttpInterface) => {
        let responseAny: any = response;
        return responseAny;
      }),
      catchError((err: any) => {
        throw err;
      })
    );
  };
  public readNotification = () => {
    return this.http.get(this.APIEndpoint + 'admin/read_notification').pipe(
      map((response: HttpInterface) => {
        let responseAny: any = response;
        return responseAny;
      }),
      catchError((err: any) => {
        throw err;
      })
    );
  };
}
