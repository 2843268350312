import * as notifyConsts from './users/notify.constant';
import * as authConsts from './users/auth.constant';
import * as massNotificationConsts from './users/mass-notification.constant';
import * as loader from './loader/loader.constant';
export default {
  ...notifyConsts,
  ...authConsts,
  ...massNotificationConsts,
  ...loader,
};
