import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import constants from '@store/_constants';
import { AppState } from '@store';
@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  constructor(private _store$: Store<AppState>) {}
  public loader = (payload?: boolean): void => {
    this._store$.dispatch({
      type: payload ? constants.START_LOADER : constants.STOP_LOADER,
      payload: payload,
    });
  };
}
