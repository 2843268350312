import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { interval, Subscription } from 'rxjs';
import { LoaderService } from '@module/shared/_services/loader/loader.service';
import { SharedService } from '@module/shared/_services/shared/shared.service';
import { AuthenticationService } from '@module/shared/_services/auth/authentication.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  public curUserName: string;
  private userObj: object;
  public isHidden: boolean = true;
  public menuuser: boolean = false;
  public menudir: boolean = false;
  public notification: number = 0;
  public accessMenuSubAdmin: boolean = false;
  subscription: Subscription;
  constructor(
    private _router: Router,
    private _loaderservice: LoaderService,
    private _service: SharedService,
    private _authService: AuthenticationService
  ) {
    this.setUserheader();
    const source = interval(60000);
    this.subscription = source.subscribe((val) => this.setUserheader());
    if (this._authService.checkCredentials()) {
      this.userObj = this._authService.getUserData();
      this.curUserName = `${this.userObj['first_name']} ${this.userObj['last_name']}`;
      this.accessMenuSubAdmin =
        this.userObj['admin_type'] === '1' ? true : false;
    }
  }

  ngOnInit() {}
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  public logout = () => {
    this._service.setUserLogout().subscribe(
      (data) => {
        if (data.status) {
          // this._router.navigate(['login']);
          this._authService.removeAccessToken();
        } else {
          console.error('response not found from server: ' + data.status);
        }
      },
      (err) => {
        console.error(err);
      }
    );
  };
  public gotoProfile = () => {
    this._router.navigate([`sub-admin/profile/${this.userObj['id']}`]);
  };
  public loaderData = (data) => {
    if (data == 'setUser') {
      this.setUserheader();
    }
    if (data == 'showloader') {
      this.isHidden = false;
    }

    if (data == 'hideloader') {
      this.isHidden = true;
    }
  };
  public setUserheader = () => {
    this._service.getnotificationCounter().subscribe(
      (data) => {
        if (data.status) {
          this.notification = data.response;
        } else {
          throw new Error(data.status);
        }
      },
      (err) => {
        throw err;
      }
    );
  };
  public gotoNotifiaction = () => {
    this.notification = 0;
    this._service.readNotification().subscribe(
      (data) => {
        if (data.status) {
          this._router.navigate(['notifications']);
        } else {
          throw new Error(data.status);
        }
      },
      (err) => {
        console.error(err);
        throw err;
      }
    );
  };
}
