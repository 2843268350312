import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-not-found',
  template: `<div class="skin-blue sidebar-mini wysihtml5-supported">
    <!-- wrapper -->
    <div class="wrapper">
      <!-- Content Wrapper. Contains page content -->
      <div class="content-wrapper">
        <!-- Main content -->
        <section class="content">
          <div class="error-page">
            <h2 class="headline text-yellow">404</h2>
            <div class="error-content">
              <h3>
                <i class="fa fa-warning text-yellow"></i> Oops! Page not found.
              </h3>
              <p>
                We could not find the page you were looking for. Meanwhile, you
                may or try using the search form.
              </p>
            </div>
          </div>
        </section>
        <!-- /.content -->
      </div>
      <!-- /.content-wrapper -->
    </div>
    <!-- ./wrapper -->
  </div> `,
})
export class NotFoundComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
