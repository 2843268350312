import { createAction, props } from '@ngrx/store';
import { NotifyUsers } from '@store/_models/users/notify.model';
import * as NotificationModel from '@store/_models/users/mass-notification.model';
import constants from '@store/_constants';
export const setUserAndNotificationTypes = (notificationTypes, user) => {
  return {
    type: constants.SET_USER_AND_NOTIFICATION_TYPES,
    payload: <NotifyUsers>{
      notify_types: notificationTypes,
      users: user,
    },
  };
};
export const fetchUserAndNotificationTypes = () => {
  return {
    type: constants.FTCH_USER_AND_NOTIFICATION_TYPES,
  };
};
export const logoutAction = createAction(constants.ADMIN_LOGOUT);
export const fetchMassNotificationList = createAction(
  constants.FETCH_ADMIN_MASS_NOTIFICATION_LIST,
  props<{ payload: NotificationModel.QueryParams }>()
);
export const setMassNotificationList = createAction(
  constants.SET_ADMIN_MASS_NOTIFICATION_LIST,
  props<{ payload: NotificationModel.NotificationListParam }>()
);
export const fetchMassNotificationDetails = createAction(
  constants.FETCH_ADMIN_MASS_NOTIFICATION_DETAILS,
  props<{ payload: NotificationModel.DetailsQueryParams }>()
);
export const setMassNotificationDetails = createAction(
  constants.SET_ADMIN_MASS_NOTIFICATION_DETAILS,
  props<{ payload: NotificationModel.NotificationListParam }>()
);
export const dropMassNotificationAction = createAction(
  constants.DROP_ADMIN_MASS_NOTIFICATION,
  props<{ payload: NotificationModel.DropQueryParams }>()
);
