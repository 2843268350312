import { NotifyUsers } from '@store/_models/users/notify.model';
import constants from '@store/_constants/';
const initialState: NotifyUsers = {
  notify_types: [],
  users: [],
};
export function userAndNotificationTypesReducer(state = initialState, action) {
  switch (action.type) {
    case constants.SET_USER_AND_NOTIFICATION_TYPES:
      return {
        ...state,
        notify_types: action.payload.notify_types,
        users: action.payload.users.filter((user: object) => {
          return user['isUserSelected'];
        }),
      };
    default:
      return state;
  }
}
