<aside class="main-sidebar">
  <!-- sidebar: style can be found in sidebar.less -->
  <section class="sidebar">
    <!-- Sidebar Menu -->
    <ul class="sidebar-menu" data-widget="tree">
      <li class="header">MENU</li>
      <!-- Optionally, you can add icons to the links -->
      <li [class.active]="(currentPath === '/dashboard')"><a [routerLink]="['/dashboard']"><i class="fa fa-sliders" aria-hidden="true"></i> <span>Dashboard</span></a></li>
      <li *ngIf="accessMenuSubAdmin" [class.active]="(currentPath === '/users')"><a [routerLink]="['/users']"><i class="fa fa-users" aria-hidden="true"></i> <span>Users</span></a></li>
      <li *ngIf="!accessMenuSubAdmin" [class.active]="(currentPath === '/user-list')"><a [routerLink]="['/promotion/user-list']"><i class="fa fa-users" aria-hidden="true"></i> <span>Promotional Users</span></a></li>
      <li *ngIf="accessMenuSubAdmin" [class.active]="(currentPath === '/sub-admin')"><a [routerLink]="['/sub-admin']"><i class="fa fa-users" aria-hidden="true"></i> <span>Sub Admins</span></a></li>
      <li *ngIf="accessMenuSubAdmin" [class.active]="(currentPath === '/users/mass-notification-list')"><a [routerLink]="['/users/mass-notification-list']"><i class="fa fa-envelope-o" aria-hidden="true"></i> <span>Mass notifications</span></a></li>
      <li [class.active]="(currentPath === '/promotional-user-list')"><a [routerLink]="['/users/promotional-user-list']"><i class="fa fa-users" aria-hidden="true"></i> <span>Leads</span></a></li>
    </ul>
    <!-- /.sidebar-menu -->
  </section>
  <!-- /.sidebar -->
</aside>