import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@module/shared/_services/auth/authentication.service';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements OnInit {
  public obj: object;
  public currentPath: string;
  public accessMenuSubAdmin: boolean = false;
  constructor(private _authService: AuthenticationService) {
    this.currentPath = window.location.href;
    if (this._authService.checkCredentials()) {
      this.obj = this._authService.getUserData();
      this.accessMenuSubAdmin = this.obj['admin_type'] === '1' ? true : false;
    }
  }
  ngOnInit() {}
}
