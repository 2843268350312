import * as NotificationModel from '@store/_models/users/mass-notification.model';
import constants from '@store/_constants';
const initialListState: NotificationModel.NotificationListParam = {
  _dataList: [],
  _totalListCount: 0,
};
export function massNotificationListReducer(state = initialListState, action) {
  switch (action.type) {
    case constants.SET_ADMIN_MASS_NOTIFICATION_LIST:
      return {
        ...state,
        _dataList: action.payload._dataList,
        _totalListCount: action.payload._totalListCount,
      };
    default:
      return state;
  }
}
const initialDetailsState: NotificationModel.NotificationDetailsParam = {
  notification: {},
  userList: [],
};
export function massNotificationDetailsReducer(
  state = initialDetailsState,
  action
) {
  switch (action.type) {
    case constants.SET_ADMIN_MASS_NOTIFICATION_DETAILS:
      return {
        ...state,
        notification: action.payload.notification,
        userList: action.payload.userList,
      };
    default:
      return state;
  }
}
