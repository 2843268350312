import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '@store';
import { NotifyUsers } from '@store/_models/users/notify.model';
import * as NotificationModel from '@store/_models/users/mass-notification.model';
export const selectedNotifyUsersUser = createSelector(
  (state: AppState) => state.userAndNotificationTypes,
  (userAndNotificationTypes: NotifyUsers) => userAndNotificationTypes
);
export const massNotificationLists = createSelector(
  (state: AppState) => state.massNotificationListTypes,
  (massNotificationListTypes: NotificationModel.NotificationListParam) =>
    massNotificationListTypes
);
export const massNotificationDetails = createSelector(
  (state: AppState) => state.massNotificationDetails,
  (massNotificationDetails: NotificationModel.NotificationDetailsParam) =>
    massNotificationDetails
);
