import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
@Component({
  selector: 'mat-table-pagination',
  template: `
    <mat-paginator
      [length]="length"
      [pageSize]="pageSize"
      [pageSizeOptions]="pageSizeOptions"
      [showFirstLastButtons]="showFirstLastButtons"
      [pageSizeOptions]="pageSizeOptions"
      [pageIndex]="pageIndex"
      (page)="handlePageEvent($event)"
    >
    </mat-paginator>
  `,
})
export class MatTablePaginationComponent implements OnInit {
  @Input() length;
  @Input() pageSize;
  @Input() pageSizeOptions;
  @Input() showFirstLastButtons;
  @Input() pageIndex;
  @Output() pageEvent = new EventEmitter();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor() {}
  ngOnInit() {}
  handlePageEvent(event: PageEvent) {
    this.pageEvent.emit(event);
  }
}
