import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from '@store';
import actions from '@store/_actions';
import { catchError, tap } from 'rxjs/operators';
import jwt_decode from 'jwt-decode';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { LoaderService } from '@module/shared/_services/loader/loader.service';
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService implements HttpInterceptor {
  private token: string;
  constructor(
    private toastr: ToastrService,
    private _router: Router,
    private _store$: Store<AppState>,
    private _loaderservice: LoaderService
  ) {}
  intercept = (
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> => {
    // Get the auth token from the service.
    this.token = this.getAccessToken();
    // Clone the request and replace the original headers with
    // cloned headers, updated with the authorization.
    const _req = req.clone({
      setHeaders: {
        Authorization: this.token,
      },
    });
    // send cloned request with header to the next handler.
    return next.handle(_req).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
          }
        },
        (error) => {
          if (error instanceof HttpErrorResponse) {
            this._loaderservice.loader(false);
            if (error.status == 0) {
              console.error(error);
              this.toastr.error('Something wrong happend', 'Oops!!', {
                timeOut: 3000,
              });
            } else if (error.status == 401) {
              console.error(error.statusText);
              this.toastr.error(
                'The token is expired!, please login',
                'Oops!!',
                { timeOut: 3000 }
              );
              this.removeAccessToken();
              // this._router.navigate(['login']);
            }
          }
        }
      )
    );
  };
  private getAccessToken = (): string => {
    if (sessionStorage.getItem('_tkn')) {
      return `Bearer ${sessionStorage.getItem('_tkn')}`;
    } else {
      return '';
    }
  };
  public checkCredentials = (): boolean => {
    if (sessionStorage.getItem('_tkn')) {
      return true;
    } else {
      return false;
    }
  };
  public setAccessToken = (_jwtToken: string): void => {
    if (_jwtToken) {
      sessionStorage.setItem('_tkn', _jwtToken);
    }
  };
  public getUserData = (): object => {
    if (this.checkCredentials()) {
      return jwt_decode(sessionStorage.getItem('_tkn'));
    } else {
      return {};
    }
  };
  public removeAccessToken = (): void => {
    if (this.checkCredentials()) {
      this._store$.dispatch(actions.logoutAction());
      sessionStorage.removeItem('_tkn');
      sessionStorage.removeItem('_pub_ip');
    }
  };
  public setPublicIP = (ip: string): void => {
    if (ip) {
      sessionStorage.setItem('_pub_ip', ip);
    }
  };
  public getPublicIP = (): string => {
    const _pub_ip = sessionStorage.getItem('_pub_ip');
    if (_pub_ip) {
      return _pub_ip;
    } else {
      return;
    }
  };
}
