import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '@module/shared/_services/auth/authentication.service';

@Component({
  selector: 'layout-root',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css'],
})
export class LayoutComponent implements OnInit {
  public currentPath: any;
  private isPublicPathIncludes: boolean;
  title = 'Admin Panel';
  constructor(
    private _router: Router //public _authService: AuthenticationService
  ) {
    // this.currentPath = location.pathname.split('/').pop();
    //const publicPathArray = ['contact-us'];
    //this.isPublicPathIncludes = publicPathArray.includes(this.currentPath);
    // if (!this._authService.checkCredentials() && !this.isPublicPathIncludes) {
    //   this._router.navigate(['location']);
    // }
  }
  ngOnInit() {
    if (!this.isPublicPathIncludes) {
      const body = document.getElementsByTagName('body')[0];
      body.classList.add('skin-blue');
      body.classList.remove('login-background');
    }
  }
}
