import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ToastrModule } from 'ngx-toastr';
import { MaterialModule } from './material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSummernoteModule } from 'ngx-summernote';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { LoadingBarModule } from '@ngx-loading-bar/core';
/* ************ */
import * as rc from './_components/rootComponents';
import * as p from './_pipes';
@NgModule({
  declarations: [
    rc.FooterComponent,
    rc.HeaderComponent,
    rc.LoderComponent,
    rc.SidebarComponent,
    rc.ReactiveFormErrorComponent,
    rc.MatTablePaginationComponent,
    rc.MassNotificationDetailsCardComponent,
    p.DateFormatPipe,
    p.LimitToPipe,
    p.CapitalizePipe,
    p.KeyFilterPipe,
    p.TextSearchPipe,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    ToastrModule.forRoot(),
    MaterialModule,
    LoadingBarRouterModule,
    LoadingBarModule,
    LoadingBarHttpClientModule,
    NgxSummernoteModule,
  ],
  exports: [
    rc.FooterComponent,
    rc.HeaderComponent,
    rc.LoderComponent,
    rc.SidebarComponent,
    rc.ReactiveFormErrorComponent,
    rc.MatTablePaginationComponent,
    rc.MassNotificationDetailsCardComponent,
    p.DateFormatPipe,
    p.LimitToPipe,
    p.CapitalizePipe,
    p.KeyFilterPipe,
    p.TextSearchPipe,
    ModalModule,
    ToastrModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    LoadingBarRouterModule,
    LoadingBarModule,
    LoadingBarHttpClientModule,
    NgxSummernoteModule,
  ],
})
export class SharedModule {}
