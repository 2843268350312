import {
  ActionReducer,
  ActionReducerMap,
  INIT,
  MetaReducer,
} from '@ngrx/store';
import { environment } from '@environments/environment';
import { NotifyUsers } from '@store/_models/users/notify.model';
import * as NotificationModel from '@store/_models/users/mass-notification.model';
import actions from '@store/_actions';
import rootReducer from '@store/_reducers';

export interface AppState {
  loaderReducer: boolean;
  userAndNotificationTypes: NotifyUsers;
  massNotificationListTypes: NotificationModel.NotificationListParam;
  massNotificationDetails: NotificationModel.NotificationDetailsParam;
}
export const reducers: ActionReducerMap<AppState> = {
  loaderReducer: rootReducer.loaderReducer,
  userAndNotificationTypes: rootReducer.userAndNotificationTypesReducer,
  massNotificationListTypes: rootReducer.massNotificationListReducer,
  massNotificationDetails: rootReducer.massNotificationDetailsReducer,
};

// console.log all actions
export function logger(
  reducer: ActionReducer<AppState>
): ActionReducer<AppState> {
  return function (state: AppState, action: any): AppState {
    //console.log('state', state);
    //console.log('action', action);
    return reducer(state, action);
  };
}
// remove all actions after logout
export function logout(
  reducer: ActionReducer<AppState>
): ActionReducer<AppState> {
  return (state, action) => {
    if (action != null && action.type === actions.logoutAction.type) {
      return reducer(undefined, { type: INIT });
    }
    return reducer(state, action);
  };
}
export const metaReducers: MetaReducer<AppState>[] = !environment.production
  ? [logger, logout]
  : [logout];
