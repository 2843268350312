import constants from '@store/_constants/';
const initialState: boolean = false;
export function loaderReducer(state = initialState, action) {
  switch (action.type) {
    case constants.START_LOADER:
      return action.payload;
    case constants.STOP_LOADER:
      return action.payload;
    default:
      return state;
  }
}
